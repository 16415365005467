






import Vue from "vue";

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      self: this,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions() {
      const self = this;
      return {
        attrs: {
          "item-key": "productId",
        },
        content: {
          name: "Sản phẩm",
          search: {},
          displayFields: {
            product: {
              text: "Sản phẩm",
              options: {
                subProp: "product.sku",
              },
            },
            crateCount: {
              text: "Số thùng",
              options: {
                filter: "number2",
              },
            },
            count: {
              text: "Số lượng order",
              options: {
                filter: "number2",
              },
            },
            cbmPerKg: {
              text: "CMB/KG",
            },
            costPerUnit: {
              text: "Đơn giá / 1 pcs",
              options: {
                preTransform(_, item) {
                  const totalCount = self.item.items.reduce((total, it) => {
                    return total + it.count;
                  }, 0);
                  let totalShipingCost = self.item.shippingCost;
                  if (self.item.localShippingCostPerItem) {
                    totalShipingCost =
                      self.item.localShippingCostPerItem * (self.item.cbmPerKg || 0) * self.item.exchangeRate;
                  }
                  if (self.item.shippingCostItems) {
                    totalShipingCost += self.item.shippingCostItems.reduce((total, it) => {
                      return total + (it.amount || 0) * (it.exchangeRate || 1);
                    }, 0);
                  }
                  return (item.unitPrice || 0) + (totalShipingCost || 0) / totalCount;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            unitPrice: {
              text: "Đơn giá order",
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            totalValue: {
              text: "Giá trị order",
              options: {
                preTransform(_, item) {
                  return (item.count || 0) * (item.unitPrice || 0);
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            doneCount: {
              text: "Số lượng đã về",
              options: {
                filter: "number2",
              },
            },
            doneValue: {
              text: "Giá trị đã về",
              options: {
                preTransform(_, item) {
                  return (item.doneCount || 0) * (item.unitPrice || 0);
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            doneCostPerUnit: {
              text: "Đơn giá / 1 pcs thực tế",
              options: {
                preTransform(_, item) {
                  const totalDoneCount = self.item.items.reduce((total, it) => {
                    return total + (it.doneCount || 0);
                  }, 0);
                  let totalShipingCost = self.item.shippingCost || 0;
                  if (self.item.localShippingCostPerItem) {
                    totalShipingCost =
                      self.item.localShippingCostPerItem * (self.item.cbmPerKg || 0) * self.item.exchangeRate;
                  }
                  if (self.item.shippingCostItems) {
                    totalShipingCost += self.item.shippingCostItems.reduce((total, it) => {
                      return total + (it.amount || 0) * (it.exchangeRate || 1);
                    }, 0);
                  }
                  return item.unitPrice + (totalShipingCost || 0) / totalDoneCount;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            remainCount: {
              text: "Số lượng chưa về",
              options: {
                preTransform(_, item) {
                  return Math.max(item.count - (item.doneCount || 0), 0);
                },
                filter: "number2",
              },
            },
            remainValue: {
              text: "Giá trị hàng chưa về",
              options: {
                preTransform(_, item) {
                  return Math.max(item.count - (item.doneCount || 0), 0) * (item.unitPrice || 0);
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            remainCostPerUnit: {
              text: "Đơn giá / 1 pcs chưa về",
              options: {
                preTransform(_, item) {
                  const totalRemainCount = self.item.items.reduce((total, it) => {
                    return total + Math.max(it.count - (it.doneCount || 0), 0);
                  }, 0);
                  let totalShipingCost = self.item.shippingCost || 0;
                  if (self.item.localShippingCostPerItem) {
                    totalShipingCost =
                      self.item.localShippingCostPerItem * (self.item.cbmPerKg || 0) * self.item.exchangeRate;
                  }
                  if (self.item.shippingCostItems) {
                    totalShipingCost += self.item.shippingCostItems.reduce((total, it) => {
                      return total + (it.amount || 0) * (it.exchangeRate || 1);
                    }, 0);
                  }
                  return item.unitPrice + (totalShipingCost || 0) / totalRemainCount;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll() {
              return [self.item.items, self.item.items.length];
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
