






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import warehouseImportPaymentStatuses from "@/constants/purchaseCommandPaymentStatuses";

export default Vue.extend({
  props: ["purchaseCommandId"],
  data() {
    return {
      self: this,
      tableOptions: null
    };
  },
  methods: {
    makeTableOptions() {
      const self = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true
        },
        content: {
          name: "Phiếu nhập kho",
          search: {},
          displayFields: {
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime"
              }
            },
            deliveriedTime: {
              text: "Ngày nhập kho",
              sortable: true,
              options: {
                filter: "dateTime"
              }
            },
            orderId: {
              text: "Mã phiếu nhập kho",
              sortable: true
            },
            description: {
              text: "Diễn giải"
            },
            product: {
              text: "Sản phẩm",
              sortable: true,
              options: {
                subProp: "product.sku"
              }
            },
            count: {
              text: "Số lượng",
              sortable: true,
              options: {
                filter: "number2"
              }
            },
            cbmPerKg: {
              text: "CMB/KG",
              sortable: true
            },
            unitPrice: {
              text: "Đơn giá",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                }
              }
            },
            shippingCost: {
              text: "Phí ship",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                }
              }
            },
            totalPayment: {
              text: "Tổng thanh toán",
              options: {
                preTransform(_, item) {
                  return (
                    (item.count || 0) * (item.unitPrice || 0) +
                    (item.shippingCost || 0)
                  );
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                }
              }
            },
            costPerUnit: {
              text: "Đơn giá / 1 pcs",
              options: {
                preTransform(_, item) {
                  return (
                    ((item.count || 0) * (item.unitPrice || 0) +
                      (item.shippingCost || 0)) /
                    (item.count || 0)
                  );
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                }
              }
            },
            trackingNumber: {
              text: "Tracking Number",
              sortable: true
            },
            paymentStatus: {
              text: "Trạng thái thanh toán phí ship về kho chính",
              sortable: true,
              options: {
                label(value) {
                  return warehouseImportPaymentStatuses[value];
                },
                attrs(value) {
                  const data: any = {};
                  switch (value) {
                    case "ctt":
                      data.color = "red";
                      break;
                    case "dtt":
                      data.color = "green";
                      break;
                  }
                  return data;
                }
              }
            },
            shippingCostPaymentStatus: {
              text: "Trạng thái thanh toán phí ship nội địa",
              sortable: true,
              options: {
                label(value) {
                  return warehouseImportPaymentStatuses[value];
                },
                attrs(value) {
                  const data: any = {};
                  switch (value) {
                    case "ctt":
                      data.color = "red";
                      break;
                    case "dtt":
                      data.color = "green";
                      break;
                  }
                  return data;
                }
              }
            },
            note: {
              text: "Ghi chú"
            },
            action: {}
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "deliveryStatus",
              operator: "equal_to",
              value: "500"
            });
            options.filters.push({
              key: "importType",
              operator: "equal_to",
              value: "nm"
            });
            options.filters.push({
              key: "purchaseCommandId",
              operator: "equal_to",
              value: self.purchaseCommandId
            });
            return options;
          }
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "warehouseImports",
                "findAll",
                options
              );
              return [items, count];
            }
          }
        }
      };
    }
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  }
});
